import { LifeCycles } from 'single-spa';
import { SubsiteOptions } from '../../../../common/types/global';
import { error } from './components/error';

const $app = document.getElementById('app-not-found');

const blurbs = {
    724631: 'Oops! The page you\'re looking for was not found.',
    724632: 'Take me home',
};

const blurbIds = Object.keys(blurbs).map(String);

function mount(props: SubsiteOptions): Promise<unknown> {
    return props.loadTranslations({ keys: blurbIds, fallback: blurbs }).then((translate) => {
        if ($app) {
            $app.innerHTML = error({
                title: '404',
                infoText: translate(724631),
                buttonText: translate(724632),
                buttonType: 'TakeMeHome',
                image: '404',
                dataQA: 'not-found',
                fullScreen: false,
            });
        }
    });
}

function unmount(): Promise<unknown> {
    if ($app) {
        $app.innerHTML = '';
    }
    return Promise.resolve();
}

export const notFound: LifeCycles<SubsiteOptions> = {
    mount,
    unmount,
    bootstrap: () => Promise.resolve(),
};
