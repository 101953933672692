import { LifeCycles } from 'single-spa';

type Options = {
    redirectUrl: string;
}

function bootstrap(): Promise<void> {
    return Promise.resolve();
}


function mount(opts: Options): Promise<void> {
    return new Promise((resolve) => {
        if (opts.redirectUrl) {
            window.location.href = opts.redirectUrl;
        }
        resolve();
    });
}

function unmount(): Promise<void> {
    return new Promise((resolve) => {
        resolve();
    });
}

export function singleSpaPlaceholder(opts: Options): LifeCycles {
    return {
        bootstrap: bootstrap.bind(null),
        mount: mount.bind(null, opts),
        unmount: unmount.bind(null),
    };
}
