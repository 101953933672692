import {
    TranslationRequest,
    TranslationResponse,
} from '../../../../server/src/types/translation';
import { config } from '../config';

export function getTranslations(
    req: TranslationRequest,
): Promise<TranslationResponse> {
    return fetch(`${config.paths.server.url}/translate`, {
        method: 'post',
        body: JSON.stringify(req),
    }).then((res) => res.json());
}
