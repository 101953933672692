import getEnvConfig from '@efcloud/el-b2b-get-env-config';
import {
    languages,
    countries,
    genders,
    departments,
    seniorities,
} from '../../../common/utils/constants';
import { EnvConfig, GeneralSubSiteConfig, GrantsAndCreditFeatures } from '../../../common/types/global';

const envConfig: EnvConfig = getEnvConfig('env-config');

const config: GeneralSubSiteConfig = {
    languages,
    countries,
    genders,
    departments,
    seniorities,
    paths: {
        currentCourse: {
            url: '/study/studyplan',
            external: false,
            blank: false,
        },
        grammarLab: {
            url: '/apps-and-tools/grammarlab',
            external: false,
            blank: false,
        },
        flashcards: {
            url: '/apps-and-tools/flashcards',
            external: false,
            blank: false,
        },
        translator: {
            url: '/apps-and-tools/translator',
            external: false,
            blank: false,
        },
        appsAndTools: {
            url: '/apps-and-tools',
            external: false,
            blank: false,
        },
        changeCourse: {
            url: '/change-course',
            external: false,
            blank: false,
        },
        privateClass: {
            url: '/classes/pl',
            external: false,
            blank: false,
        },
        currentBookings: {
            url: '/classes/mybookings',
            external: false,
            blank: false,
        },
        groupClass: {
            url: '/classes/gl',
            external: false,
            blank: false,
        },
        helpCenter: {
            url: envConfig.helpCenterUrl,
            external: true,
            blank: true,
        },
        onboarding: {
            url: '/onboarding',
            external: false,
            blank: false,
        },
        accountSettings: {
            url: '/account-settings',
            external: false,
            blank: false,
        },
        progressAndGoals: {
            url: '/progress-goals',
            external: false,
            blank: false,
        },
        home: {
            url: '/home',
            external: false,
            blank: false,
        },
        notFound: {
            url: '/not-found',
            external: false,
            blank: false,
        },
        efset30: {
            url: '/efset30',
            external: false,
            blank: false,
        },
        efset30Retake: {
            url: '/efset30?retake=true',
            external: false,
            blank: false,
        },
        efset50: {
            url: '/assessment',
            external: false,
            blank: false,
        },
        levelTest: {
            url: '/study/leveltest',
            external: false,
            blank: false,
        },
        errorPage: {
            url: '/error',
            external: false,
            blank: false,
        },
        server: {
            url: '/api/bff',
            external: false,
            blank: false,
        },
        root: {
            url: '/',
            external: false,
            blank: false,
        },
    },
};

const gapPaths: GeneralSubSiteConfig['paths'] = {
    currentCourse: {
        url: '/wl/study-plan?product=b2b',
        external: true,
        blank: false,
    },
    changeCourse: {
        url: '/wl/change-course?product=b2b',
        external: true,
        blank: false,
    },
    onboarding: {
        url: '/wl/onboarding?product=b2b',
        external: true,
        blank: false,
    },
    progressAndGoals: {
        url: '/wl/progress-goals?product=b2b',
        external: true,
        blank: false,
    },
};

const hultEfProPaths: GeneralSubSiteConfig['paths'] = {
    ...gapPaths,
    home: {
        url: '/wl/home?product=b2b',
        external: true,
        blank: false,
    },
    placeholderHome: {
        url: '/home',
        external: false,
        blank: false,
    },
};

function pathsRegex(paths: string[]): RegExp {
    return new RegExp(`^(${paths.join('|')})\\/?$`, 'i');
}

const pathPatterns = {
    // eslint-disable-next-line
    onboarding: pathsRegex([config.paths.onboarding.url]),
    changeCourse: pathsRegex([config.paths.changeCourse.url]),
    currentCourse: pathsRegex([config.paths.currentCourse.url]),
    grammarLab: pathsRegex([config.paths.grammarLab.url]),
    flashcards: pathsRegex([config.paths.flashcards.url]),
    translator: pathsRegex([config.paths.translator.url]),
    appsAndTools: pathsRegex([config.paths.appsAndTools.url]),
    accountSettings: pathsRegex([config.paths.accountSettings.url]),
    efset30: pathsRegex([config.paths.efset30.url]),
    efset50: pathsRegex([config.paths.efset50.url]),
    progressAndGoals: pathsRegex([config.paths.progressAndGoals.url]),
    home: pathsRegex([config.paths.home.url]),
    root: pathsRegex([config.paths.root.url]),
    currentBookings: pathsRegex([config.paths.currentBookings.url]),
    privateClass: pathsRegex([config.paths.privateClass.url]),
    groupClass: pathsRegex([config.paths.groupClass.url]),
    levelTest: pathsRegex([config.paths.levelTest.url]),
    errorPage: pathsRegex([config.paths.errorPage.url]),
};

function getPaths(grantsAndCredits: GrantsAndCreditFeatures | undefined): GeneralSubSiteConfig['paths'] {
    if (grantsAndCredits?.hultEfPro) {
        return { ...config.paths, ...hultEfProPaths };
    }

    if (grantsAndCredits?.catalyst) {
        return { ...config.paths, ...gapPaths };
    }

    return config.paths;
}

export {
    envConfig,
    config,
    gapPaths,
    hultEfProPaths,
    pathPatterns,
    pathsRegex,
    getPaths,
};
