import {
    ActivityFn, Application, CustomPropsFn, LifeCycles, registerApplication, start,
} from 'single-spa';
import { Student } from '../../../server/src/types/student';
import { getLanguage, loadTranslations } from './translations';
import {
    onStudentChange, changeStudent, onLanguageChange, changeLanguage, onMessage, showLoading,
} from './messaging';
import {
    envConfig, config, pathPatterns, pathsRegex, getPaths,
} from './config';
import { trackEvent } from './utils/tracking';
import {
    SubsiteOptions, B2BLanguage, InitializeResponse, GeneralSubSiteConfig,
} from '../../../common/types/global';
import { GrantsAndCreditType } from '../../../server/src/types/GrantsAndCredits';
import { errorPage } from './views/errorPage';
import { notFound } from './views/notFound';
import { efset30 } from './views/efset30';
import { efset50 } from './views/efset50';
import { iframeApp } from './views/iframeApp';
import { showChatButton, hideChatButton, loadLivechat } from './utils/livechat';
import { singleSpaPlaceholder } from './views/placeholderApp';

interface ValidRoute {
    name: string;
    moduleLoader: Application<SubsiteOptions>;
    activityFunc: ActivityFn;
    customProps?: SubsiteOptions | CustomPropsFn<SubsiteOptions>;
    path: string;
    showHeaderFooter: boolean;
}

window.SystemJS = window.System || {};

let currentStudent: Student | null = null;
onStudentChange((student) => {
    currentStudent = student;
});

let livechatInitialised: boolean;

function hasFeature(student: Student, feature: GrantsAndCreditType): boolean {
    return student.grantsAndCredits[feature] !== undefined;
}

// Some props may change subsequent to initialisation so they must be injected on demand
// each time the module is mounted
// this includes language and the current student
function injectDynamicProps<T>(mod: LifeCycles<T>): LifeCycles<T> {
    const { mount } = mod;
    // eslint-disable-next-line no-param-reassign, @typescript-eslint/no-explicit-any
    mod.mount = (props: any): Promise<null> => getLanguage()
        .then((lang) => {
            const updatedProps = {
                ...props,
                lang,
                student: currentStudent || props.student,
            };

            if (mount instanceof Array) {
                return mount.forEach((m) => m(updatedProps));
            }
            return mount(updatedProps);
        });
    return mod;
}

function loadPlaceholderPage(paths: GeneralSubSiteConfig['paths']): Promise<LifeCycles> {
    return new Promise((resolve) => {
        resolve(singleSpaPlaceholder({
            redirectUrl: paths.home.url,
        }));
    });
}

function loadModuleWithSpinner<T>(path: string): Promise<LifeCycles<T>> {
    showLoading(true);
    return SystemJS.import(path)
        .then((m) => injectDynamicProps(m))
        .finally(() => showLoading(false));
}

function getValidRoutes(customProps: SubsiteOptions, student?: Student): ValidRoute[] {
    const errorRoute: ValidRoute = {
        name: 'errorPage',
        moduleLoader: errorPage,
        activityFunc: (location: Location) => pathPatterns.errorPage.test(location.pathname),
        customProps,
        path: config.paths.errorPage.url,
        showHeaderFooter: false,
    };
    if (!student) {
        // student *could* be undefined here if the student has no activation period.
        // i.e. UserStatus.Unlicensed
        // we still initialise the router under those circumstances
        // but the only valid route (currently) is the errorPage
        return [errorRoute];
    }
    const validRoutes: ValidRoute[] = [errorRoute, {
        name: 'accountsettings',
        moduleLoader: (): Promise<LifeCycles<SubsiteOptions>> => loadModuleWithSpinner('@portal/accountsettings'),
        activityFunc: (location: Location): boolean => pathPatterns.accountSettings.test(location.pathname),
        customProps,
        path: config.paths.accountSettings.url,
        showHeaderFooter: true,
    }, {
        name: 'efset30',
        moduleLoader: injectDynamicProps(efset30),
        activityFunc: (location: Location): boolean => pathPatterns.efset30.test(location.pathname),
        customProps,
        path: config.paths.efset30.url,
        showHeaderFooter: false,
    }, {
        name: 'grammarLab',
        moduleLoader: iframeApp,
        activityFunc: (location: Location): boolean => pathPatterns.grammarLab.test(location.pathname),
        customProps,
        path: config.paths.grammarLab.url,
        showHeaderFooter: true,
    }, {
        name: 'flashcards',
        moduleLoader: iframeApp,
        activityFunc: (location: Location): boolean => pathPatterns.flashcards.test(location.pathname),
        customProps,
        path: config.paths.flashcards.url,
        showHeaderFooter: true,
    }, {
        name: 'translator',
        moduleLoader: iframeApp,
        activityFunc: (location: Location): boolean => pathPatterns.translator.test(location.pathname),
        customProps,
        path: config.paths.translator.url,
        showHeaderFooter: true,
    }, {
        name: 'appsAndTools',
        moduleLoader: iframeApp,
        activityFunc: (location: Location): boolean => pathPatterns.appsAndTools.test(location.pathname),
        customProps,
        path: config.paths.appsAndTools.url,
        showHeaderFooter: true,
    }, {
        name: 'levelTest',
        moduleLoader: (): Promise<LifeCycles<SubsiteOptions>> => loadModuleWithSpinner('@portal/leveltest'),
        activityFunc: (location: Location): boolean => pathPatterns.levelTest.test(location.pathname),
        customProps,
        path: config.paths.levelTest.url,
        showHeaderFooter: true,
    }, {
        name: 'root',
        moduleLoader: (): Promise<LifeCycles> => loadPlaceholderPage(config.paths),
        activityFunc: (location: Location): boolean => pathPatterns.root.test(location.pathname),
        customProps,
        path: config.paths.root.url,
        showHeaderFooter: false,
    }];

    if (student && !hasFeature(student, 'catalyst')) {
        validRoutes.push({
            name: 'studyplan',
            moduleLoader: iframeApp,
            activityFunc: (location: Location): boolean => pathPatterns.currentCourse.test(location.pathname),
            customProps,
            path: config.paths.currentCourse.url,
            showHeaderFooter: true,
        });
        validRoutes.push({
            name: 'changecourse',
            moduleLoader: (): Promise<LifeCycles<SubsiteOptions>> => loadModuleWithSpinner('@portal/changecourse'),
            activityFunc: (location: Location): boolean => pathPatterns.changeCourse.test(location.pathname),
            customProps,
            path: config.paths.changeCourse.url,
            showHeaderFooter: true,
        });
        validRoutes.push({
            name: 'onboarding',
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            moduleLoader: (): Promise<any> => SystemJS.import('@portal/onboarding').then((m) => injectDynamicProps(m)),
            activityFunc: (location: Location): boolean => pathPatterns.onboarding.test(location.pathname),
            customProps,
            path: config.paths.onboarding.url,
            showHeaderFooter: false,
        });
        validRoutes.push({
            name: 'progressandgoals',
            moduleLoader: (): Promise<LifeCycles<SubsiteOptions>> => loadModuleWithSpinner('@portal/progressandgoals'),
            activityFunc: (location: Location): boolean => pathPatterns.progressAndGoals.test(location.pathname),
            customProps,
            path: config.paths.progressAndGoals.url,
            showHeaderFooter: true,
        });
    }

    if (student) {
        if (!hasFeature(student, 'hultEfPro')) {
            validRoutes.push({
                name: 'home',
                moduleLoader: (): Promise<LifeCycles<SubsiteOptions>> => loadModuleWithSpinner('@portal/home'),
                activityFunc: (location: Location): boolean => pathPatterns.home.test(location.pathname),
                customProps,
                path: config.paths.home.url,
                showHeaderFooter: true,
            });
        } else {
            validRoutes.push({
                name: 'home',
                moduleLoader: (): Promise<LifeCycles> => loadPlaceholderPage(config.paths),
                activityFunc: (location: Location): boolean => pathPatterns.home.test(location.pathname),
                customProps,
                path: config.paths.placeholderHome.url,
                showHeaderFooter: false,
            });
        }
    }

    if (student && (hasFeature(student, 'privateLesson') || hasFeature(student, 'unitReview') || hasFeature(student, 'hultEfPro'))) {
        validRoutes.push({
            name: 'currentBookings',
            moduleLoader: iframeApp,
            activityFunc: (location: Location) => pathPatterns.currentBookings.test(location.pathname),
            customProps,
            path: `${config.paths.currentBookings.url}`,
            showHeaderFooter: true,
        });
    }

    if (student && (hasFeature(student, 'privateLesson') || hasFeature(student, 'unitReview'))) {
        validRoutes.push({
            name: 'privateClass',
            moduleLoader: iframeApp,
            activityFunc: (location: Location) => pathPatterns.privateClass.test(location.pathname),
            customProps,
            path: `${config.paths.privateClass.url}`,
            showHeaderFooter: true,
        });
    }

    if (student && hasFeature(student, 'efset50')) {
        validRoutes.push({
            name: 'efset50',
            moduleLoader: injectDynamicProps(efset50),
            activityFunc: (location: Location) => pathPatterns.efset50.test(location.pathname),
            customProps,
            path: config.paths.efset50.url,
            showHeaderFooter: false,
        });
    }

    if (student && hasFeature(student, 'groupDiscussion')) {
        validRoutes.push({
            name: 'groupClass',
            moduleLoader: iframeApp,
            activityFunc: (location: Location) => pathPatterns.groupClass.test(location.pathname),
            customProps,
            path: `${config.paths.groupClass.url}`,
            showHeaderFooter: true,
        });
    }

    return validRoutes;
}

export function configureRoutes(response: InitializeResponse): void {
    const { student, externalLogo } = response;

    const subsiteOptions = {
        loadTranslations,
        onLanguageChange,
        changeLanguage,
        showLoading,
        config,
        envConfig,
        onMessage,
        student,
        trackEvent,
        onStudentChange,
        changeStudent,
        lang: 'en' as B2BLanguage, // this will get overridden with the current value when a subsite gets mounted
        externalLogo,
    };

    subsiteOptions.config.paths = getPaths(response.student?.grantsAndCredits);

    const validRoutes = getValidRoutes(subsiteOptions, student);

    const validPaths = validRoutes.map((route) => route.path);

    const pathsWithoutHeaderFooter = validRoutes
        .filter((route) => !route.showHeaderFooter)
        .map((route) => route.path);

    const hideHeaderFooterRegEx = pathsRegex(pathsWithoutHeaderFooter);
    const validPathRegEx = pathsRegex(validPaths);

    // the header foot is a special case since it is not associated with any particular route
    registerApplication<SubsiteOptions>(
        'headerfooter',
        () => SystemJS.import('@portal/headerfooter').then((m) => injectDynamicProps(m)),
        (location: Location) => !hideHeaderFooterRegEx.test(location.pathname),
        subsiteOptions,
    );

    // the notFound route is special because it is dynamic and depends on the valid routes themselves
    registerApplication<SubsiteOptions>(
        'not-found',
        notFound,
        (location: Location) => !validPathRegEx.test(location.pathname),
        subsiteOptions,
    );

    validRoutes.forEach((route) => {
        registerApplication<SubsiteOptions>(
            route.name,
            route.moduleLoader,
            route.activityFunc,
            route.customProps,
        );
    });

    window.addEventListener('single-spa:routing-event', (e: unknown) => {
        // eslint-disable-next-line
        // @ts-ignore
        if (!e.detail) {
            return;
        }

        // eslint-disable-next-line
        // @ts-ignore
        const mountedApps = e.detail.appsByNewStatus.MOUNTED;
        let helpButtonVisible = false;

        if (mountedApps.includes('accountsettings') || mountedApps.includes('studyplan')) {
            helpButtonVisible = true;
        }

        if (!livechatInitialised) {
            livechatInitialised = true;

            const liveChatGrant = student?.grantsAndCredits.liveChat;
            const hasliveChatGrant = liveChatGrant && liveChatGrant.quota === 'unlimited';

            if (student && hasliveChatGrant && envConfig?.liveChatV2) {
                loadLivechat(envConfig.liveChatV2, 'en', {
                    firstName: student.firstName,
                    lastName: student.lastName,
                    emailAddress: student.emailAddress,
                    userId: student.userId,
                }, helpButtonVisible);
            }
        } else if (helpButtonVisible) {
            showChatButton();
        } else {
            hideChatButton();
        }
    });

    start();
}

// We need to trigger a reload on a routing event to '/' (e.g. via back button)
// otherwise the App Shell loads the 404 page
window.addEventListener('single-spa:before-routing-event', (e: unknown) => {
    // eslint-disable-next-line
    // @ts-ignore
    if (!e.detail) {
        return;
    }

    const parser = document.createElement('a');
    // eslint-disable-next-line
    // @ts-ignore
    parser.href = e.detail.newUrl;

    // Avoiding cancelling navigation on initial load of '/' path
    // to allow redirects in bootstrap function to take precendence
    // eslint-disable-next-line
    // @ts-ignore
    if ((parser.pathname === '/' || parser.pathname === '') && e.detail.newUrl !== e.detail.oldUrl) {
        setTimeout(() => {
            // eslint-disable-next-line
            // @ts-ignore
            e.detail.cancelNavigation();
            window.location.pathname = '/';
        }, 100);
    }
});
