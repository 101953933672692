import { B2BLanguage, LanguageObject } from '../../../../common/types/global';

// https://github.com/ef-ice/efset/wiki/Locale-and-ISO-codes
export type EfsetLanguage =
    | 'zh'
    | 'zht'
    | 'nl'
    | 'en'
    | 'fr'
    | 'de'
    | 'id'
    | 'it'
    | 'ko'
    | 'ja'
    | 'br'
    | 'ru'
    | 'es'
    | 'th'
    | 'vi';

const efsetLanguageMapper = (lang: B2BLanguage): EfsetLanguage => {
    switch (lang) {
        case 'es-CL':
            return 'es';
        case 'ko-KR':
            return 'ko';
        case 'ja-JP':
            return 'ja';
        case 'zh-HK':
            return 'zht';
        case 'zh-TW':
            return 'zht';
        case 'zh-CN':
            return 'zh';
        case 'id-ID':
            return 'id';
        case 'pt-BR':
            return 'br';
        case 'pl-PL':
            return 'en';
        case 'tr-TR':
            return 'en';
        case 'ar':
            return 'en';
        case 'uk':
            return 'en';
        case 'rw-RW':
            return 'en';
        default:
            // en, es, fr, de, it, th, ru
            return lang;
    }
};

const filterEfsetLanguages = (languages: LanguageObject[]): LanguageObject[] => {
    const availableLanguages = [
        'zh-CN',
        'zh-TW',
        'fr',
        'de',
        'id-ID',
        'it',
        'ko-KR',
        'ja-JP',
        'pt-BR',
        'ru',
        'es-CL',
        'th',
        'en',
    ];

    return languages.filter((lang) => availableLanguages.includes(lang.value));
};

export { efsetLanguageMapper, filterEfsetLanguages };
