// This is a kind of explicit pubsub layer. Rather than have generic pubsub, I have gone for an explicit
// layer of indirection that just facititates the parent child coms that we know about. This _could_ be
// replaced with something more general purpose but I'm not sure whether it should be.

import {
    B2BLanguage, LanguageChangeSubscription, LanguageChangeUnsubscribe, SubsiteMessage, MessageDisplay,
    StudentChangeSubscription, StudentChangeUnsubscribe, LoadTranslations,
} from '../../../common/types/global';
import { changeLanguage as cl, loadTranslations } from './translations';
import { debug } from './utils/logging';
import { Student } from '../../../server/src/types/student';
import { union } from './utils/set';

let languageChangeSubscriptions: LanguageChangeSubscription[] = [];
let studentChangeSubscriptions: StudentChangeSubscription[] = [];

export function onLanguageChange(sub: LanguageChangeSubscription): LanguageChangeUnsubscribe {
    languageChangeSubscriptions.push(sub);
    return (): void => {
        languageChangeSubscriptions = languageChangeSubscriptions.filter((s) => s !== sub);
    };
}

export function mergeTranslationRequests(a: LoadTranslations, b: LoadTranslations): LoadTranslations {
    return {
        keys: [...union(new Set(a.keys), new Set(b.keys))],
        fallback: {
            ...a.fallback,
            ...b.fallback,
        },
    };
}

export async function changeLanguage(lang: B2BLanguage): Promise<void> {
    await cl(lang);
    // eslint-disable-next-line max-len
    const translationsRequest = languageChangeSubscriptions.reduce<LoadTranslations>((agg, sub) => mergeTranslationRequests(agg, sub.translationsRequest()), { keys: [], fallback: {} });
    const translate = await loadTranslations(translationsRequest);
    languageChangeSubscriptions.map((sub) => sub.callback(lang, translate));
}

export function onStudentChange(sub: StudentChangeSubscription): StudentChangeUnsubscribe {
    studentChangeSubscriptions.push(sub);
    return (): void => {
        studentChangeSubscriptions = studentChangeSubscriptions.filter((s) => s !== sub);
    };
}

export async function changeStudent(student: Student): Promise<void> {
    studentChangeSubscriptions.map((callback) => callback(student));
}

function logMessage(message: SubsiteMessage): void {
    if (!message.log) return;

    switch (message.type) {
        case 'error': debug(`Error: ${message.log}`);
            break;
        case 'warning': debug(`Warning: ${message.log}`);
            break;
        case 'information': debug(`Information: ${message.log}`);
            break;
        case 'success': debug(`Success: ${message.log}`);
            break;
        default:
            debug(`Unknown: ${message}`);
    }
}

export async function showBanner(autohide: boolean, type: string, { title, message }: MessageDisplay): Promise<void> {
    const banner: HTMLElement | null = document.querySelector('.js-message-banner');
    const bannerMessage: HTMLElement | null = document.querySelector('.js-message-banner__message');
    const bannerTitle: HTMLElement | null = document.querySelector('.js-message-banner__title');
    const dismiss: HTMLElement | null = document.querySelector('.js-message-banner__button');

    if (banner && bannerMessage && bannerTitle && dismiss) {
        banner.classList.remove('-success');
        banner.classList.remove('-info');
        banner.classList.remove('-error');
        banner.classList.add(`-${type}`);
        banner.style.visibility = 'visible';
        banner.style.transform = 'translateY(0)';
        bannerTitle.textContent = title; // this needs to either be passed in or inferred from the type - let's wait for a use case
        bannerMessage.textContent = message;
        if (autohide) {
            setTimeout(() => {
                banner.style.transform = 'translateY(100%)';
            }, 3000);
        } else {
            // Not crazy about this translation lookup here but it does the job
            const dismissKey = '717581';
            const lookup = await loadTranslations({ keys: [dismissKey] });
            dismiss.textContent = lookup(dismissKey);
            dismiss.addEventListener('click', function closeBanner() {
                banner.style.transform = 'translateY(100%)';
                dismiss.removeEventListener('click', closeBanner);
            });
        }
    }
}

function displayMessage(message: SubsiteMessage): void {
    const { display, type } = message;
    if (!display) return;

    showBanner(type === 'success', type, display);
}

export function onMessage(message: SubsiteMessage): void {
    try {
        logMessage(message);
        displayMessage(message);
    } catch (err) {
        // there's really nothing we can do here if our error logging is throwing an error
        // so we need to swallow it to avoid getting into an infinite loop
    }
}

export function showLoading(showSpinner: boolean): void {
    const $spinner: HTMLElement | null = document.querySelector('.js-loading-spinner');

    if ($spinner) {
        $spinner.style.display = showSpinner ? 'flex' : 'none';
    }
}
