import amplitude from 'amplitude-js';
import { envConfig } from '../config';
import { error } from './logging';
import { Student } from '../../../../server/src/types/student';

let initialised = false;

// TODO - we _could_ consider restricting this type signature so that only know event types can be logged?
// but is there any point?
export function trackEvent(name: string, e?: unknown): void {
    if (window.Cypress || !initialised) {
        return;
    }
    amplitude.logEvent(name, e);
}

export function initTracking(student: Student|undefined): void {
    if (window.Cypress) {
        return;
    }
    if (!envConfig.amplitudeId) {
        error('Cannot initialise tracking because we do not have an api key for amplitude');
    }
    if (!student) {
        error('Cannot initialise tracking because we do not have student');
    } else {
        amplitude.getInstance().init(envConfig.amplitudeId, student.id, {
            trackingOptions: {
                // eslint-disable-next-line @typescript-eslint/camelcase
                ip_address: false,
            },
        });
        initialised = true;
    }
}
