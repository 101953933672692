// util to lazily load resource translations required by individual subsites
// resources are cached to local storage and we respect the max age specified by the
// api when checking the cache
import { pipe } from 'fp-ts/lib/pipeable'; // eslint-disable-line import/no-unresolved
import {
    fromNullable, fold, none, some, Option, map,
} from 'fp-ts/lib/Option'; // eslint-disable-line import/no-unresolved
import { getItem, setItem } from './utils/storage';
import {
    B2BLanguageKey, B2BLanguage, ResourceLookupFunc, Replacement, LoadTranslations,
} from '../../../common/types/global';
import { getTranslations } from './services/translation';
import { TranslationResponse } from '../../../server/src/types/translation';
import { error } from './utils/logging';
import { config } from './config';

export type ResourceCache = {
    [key in B2BLanguageKey]: Lookup;
};

// this is our internal representation
export interface Translation {
    maxAge: number;
    loadedAt: Date;
    loadedAtPosix: number;
    value: string;
}

type Lookup = {
    [id: string]: Translation;
}

// this is where we are going to keep hold of any translations that we have already done
// the default is an empty record, we will overwrite this with whatever gets loaded from
// local storage
let resourceCache: ResourceCache = {
    fallback: {},
    en: {},
    'pt-BR': {},
    'es-CL': {},
    es: {},
    fr: {},
    de: {},
    it: {},
    'pl-PL': {},
    uk: {},
    'ko-KR': {},
    'ja-JP': {},
    'zh-HK': {},
    'zh-TW': {},
    'zh-CN': {},
    th: {},
    ru: {},
    'tr-TR': {},
    'id-ID': {},
    'rw-RW': {},
    ar: {},
};

/**
 * Replacing the placeholders with the given substitutes
 */
export function replace(str: string, substitutes: Replacement[]): string {
    return substitutes.reduce((result, [key, val]) => result.replace(`{{${key}}}`, val), str);
}

export function validateSubstitutions(key: string, translated: string, substitutes: Replacement[]): Option<string[]> {
    const errorMsg = [];

    substitutes.forEach((sub) => {
        if (!translated.includes(sub[0])) {
            errorMsg.push(`Blurb "${translated}" with ID ${key} doesn't contain placeholder "${sub[0]}"`);
        }
    });

    const placeholderCount = translated.match(/{{/g);
    if (placeholderCount && placeholderCount.length > substitutes.length) {
        errorMsg.push(`Did not receive enough substitutes for BlurbId "${key}" with value "${translated}"`);
    }
    if (placeholderCount && placeholderCount.length < substitutes.length) {
        errorMsg.push(`Received too many substitutes for BlurbId "${key}" with value "${translated}"`);
    }

    return errorMsg.length ? some(errorMsg) : none;
}

/**
 * Try to get the key from the proper language cache (from the api). If not found fallback to the backup value.
 * If we still can't find it then we just use the default value.
 * @param lang
 * @param cache
 * @param key
 * @param def
 */
export function getRawTranslation(lang: B2BLanguage, cache: ResourceCache, key: string, def: string): string {
    return pipe(
        fromNullable(cache[lang][key]),
        fold(
            () => {
                error(`BlurbId: ${key} not found in the values returned from the api. Falling back to backup.`);
                return pipe(
                    fromNullable(cache.fallback[key]),
                    fold(
                        () => {
                            error(`BlurbId: ${key} not found in the hardcoded fallback values provided. `
                                + 'Falling back to the general unknown value.');
                            return def;
                        },
                        (t) => t.value,
                    ),
                );
            },
            (t) => t.value,
        ),
    );
}

function mergeResourceValues(lang: B2BLanguageKey, cache: ResourceCache, translations: TranslationResponse): ResourceCache {
    // eslint-disable-next-line no-param-reassign
    cache[lang] = {
        ...cache[lang],
        ...translations,
    };
    return cache;
}

function lookupFunc(lang: B2BLanguage, translations: TranslationResponse): ResourceLookupFunc {
    resourceCache = mergeResourceValues(lang, resourceCache, translations);
    setItem<ResourceCache>('i18nCache', resourceCache);
    return (key: string | number, substitutes: Replacement[] = [], defVal = 'UNKNOWN', skipSubstituteCheck = false): string => {
        const k = String(key);
        const translatedString = getRawTranslation(lang, resourceCache, k, defVal);
        if (!skipSubstituteCheck) {
            map(error)(validateSubstitutions(k, translatedString, substitutes));
        }
        return substitutes.length ? replace(translatedString, substitutes) : translatedString;
    };
}


export function expired(now: Date, translation: Translation | null | undefined): boolean {
    return translation == null
        || new Date(translation.loadedAt).getTime() + translation.maxAge * 1000 < now.getTime();
}

// return only the keys that we do not already have translations for or keys where the translations that we
// have have expired
export function findMissingKeys(cache: ResourceCache, lang: B2BLanguage, keys: string[]): string[] {
    const now = new Date();
    return keys.reduce<string[]>((agg, k) => {
        if (expired(now, cache[lang][k])) {
            agg.push(k);
        }
        return agg;
    }, []);
}

export function getLanguage(): Promise<B2BLanguage> {
    return getItem<B2BLanguage>('lang').then((lng) => lng || 'en');
}

function makeFallbackTranslation(val: string): Translation {
    const loadedAt = new Date();
    return {
        loadedAt,
        loadedAtPosix: loadedAt.getTime(),
        maxAge: Number.MAX_VALUE, // fallbacks (almost) never expire
        value: val,
    };
}

function makeFallbackTranslations(fallbacks: {[id: string]: string}): TranslationResponse {
    return Object.entries(fallbacks).reduce<TranslationResponse>((agg, [k, v]) => {
        // eslint-disable-next-line no-param-reassign
        agg[k] = makeFallbackTranslation(v);
        return agg;
    }, {});
}

export async function loadTranslations(request: LoadTranslations): Promise<ResourceLookupFunc> {
    const lang = await getLanguage();
    if (request.fallback) {
        resourceCache = mergeResourceValues('fallback', resourceCache, makeFallbackTranslations(request.fallback));
    }
    const missingKeys = findMissingKeys(resourceCache, lang, request.keys);

    if (missingKeys.length === 0) return lookupFunc(lang, {});

    const translations = await getTranslations({
        lang,
        keys: missingKeys,
    });
    return lookupFunc(lang, translations);
}

export function setLanguageRelevantAttributes(lng: B2BLanguage): void {
    const htmlTag = document.getElementsByTagName('html')[0];
    htmlTag.setAttribute('dir', lng === 'ar' ? 'rtl' : 'ltr');
    htmlTag.setAttribute('lang', lng);
}

export async function changeLanguage(lng: B2BLanguage): Promise<B2BLanguage> {
    await fetch(`${config.paths.server.url}/preferences/setlanguage?lang=${lng}`, { method: 'post' });
    setLanguageRelevantAttributes(lng);
    return setItem('lang', lng);
}

// initialisation just involves loading stuff from local storage
export function initialI18n(): Promise<ResourceCache> {
    const debugBlurbs = localStorage.getItem('debugBlurbs') === 'true';
    getLanguage().then(setLanguageRelevantAttributes);

    if (debugBlurbs) {
        return Promise.resolve(resourceCache);
    }
    return getItem<ResourceCache>('i18nCache')
        .then((cache) => {
            if (cache) {
                resourceCache = cache;
            }
            return resourceCache;
        });
}
