import { GrantsAndCreditFeatures, Limited } from '../../server/src/types/GrantsAndCredits';
import { Student } from '../../server/src/types/student';
import { B2BLanguage } from '../../server/src/types/Language';

export { InitializeResponse, RefreshAccountTokenResponse } from '../../server/src/types/auth';

export type AuthTokens = {
    access: string;
    account: string;
};

export interface MessageDisplay {
    title: string;
    message: string;
}

interface Message {
    display?: MessageDisplay;
    log?: {
        context: string;
        message: string;
    };
}

export interface ErrorMessage extends Message {
    type: 'error';
}

export interface WarningMessage extends Message {
    type: 'warning';
}

export interface InfoMessage extends Message {
    type: 'information';
}

export interface SuccessMessage extends Message {
    type: 'success';
}

export type SubsiteMessage = ErrorMessage | WarningMessage | InfoMessage | SuccessMessage;

export type LanguageObject = {
    value: B2BLanguage;
    label: string;
};

export type B2BLanguageKey = 'fallback' | B2BLanguage;

export type Replacement = [ string, string ];

export type ResourceLookupFunc = (key: string|number, substitutions?: Replacement[], defaultVal?: string) => string;

export interface LoadTranslations {
    keys: string[];
    fallback?: { [id: string]: string };
}

export type LanguageChangeSubscription = {
    translationsRequest: () => LoadTranslations;
    callback: (lang: B2BLanguage, translate: ResourceLookupFunc) => void;
}

export type LanguageChangeUnsubscribe = () => void;

export type StudentChangeSubscription = (student: Student) => void;

export type StudentChangeUnsubscribe = () => void;

export type EnvConfig = {
    amplitudeId: string;
    helpCenterUrl: string;
    iframeSrc: {
        currentCourse: string;
        grammarLab: string;
        flashcards: string;
        translator: string;
        appsAndTools: string;
        privateClass: string;
        groupClass: string;
        currentBookings: string;
        levelTest: string;
    };
    liveChat: {
        scriptUrl: string;
        liveAgentUrl: string;
        deploymentId: string;
        organizationId: string;
        buttonId: string;
    },
    liveChatV2: {
        baseCoreURL: string;
        communityEndpointURL: string;
        organizationId: string;
        eswConfigDevName: string;
        baseLiveAgentContentURL: string;
        deploymentId: string;
        buttonId: string;
        baseLiveAgentURL: string;
        eswLiveAgentDevName: string;
        scriptURL: string;
    };
    efset30: {
        webIntegrationId: string;
        webRetakeIntegrationId: string;
        mobileIntegrationId: string;
        script: string;
    };
    efset50: {
        integrationId: string;
        script: string;
    };
}


export enum ErrorPageTypes {
    Fatal = 'fatal',
    InApp = 'in-app',
    Unlicensed = 'unlicensed',
    NoEfset50Credits = 'no-efset50-credits',
}

export interface GeneralSubSiteConfig {
    languages: {
        label: string;
        value: string;
    }[];
    countries: unknown;
    genders: unknown;
    departments: unknown;
    seniorities: unknown;
    paths: {
        [key: string]: {
            url: string;
            external: boolean;
            blank: boolean;
        };
    };
}

export type SubsiteOptions = {
    onMessage: (msg: SubsiteMessage) => void;
    onLanguageChange: (sub: LanguageChangeSubscription) => LanguageChangeUnsubscribe;
    onStudentChange: (sub: StudentChangeSubscription) => StudentChangeUnsubscribe;
    changeLanguage: (lng: B2BLanguage) => Promise<void>;
    loadTranslations: (request: LoadTranslations) => Promise<ResourceLookupFunc>;
    showLoading: (isLoading: boolean) => void;
    student?: Student;
    envConfig: EnvConfig;
    config: GeneralSubSiteConfig;
    trackEvent: (name: string, e?: unknown) => void;
    lang: B2BLanguage;
    name?: string;
    externalLogo?: string;
}

export { GrantsAndCreditFeatures, Limited, B2BLanguage };
