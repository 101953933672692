export function clearHashFragment(keys: string[]): void {
    window.location.hash = keys.reduce((hash, key) => {
        const regex = new RegExp(`${key}=[^;]*;?`);
        return hash.replace(regex, '');
    }, window.location.hash);
}

export function setHashFragment(params: Record<string, string>): void {
    window.location.hash = Object.keys(params).reduce((hash, key) => {
        const regex = new RegExp(`${key}=[^;]*;?`);

        if (regex.test(hash)) {
            return hash.replace(regex, `${key}=${params[key]};`);
        }

        const separator = hash.endsWith(';') ? '' : ';';
        return hash.concat(`${separator}${key}=${params[key]};`);
    }, window.location.hash);
}
