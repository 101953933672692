import { LifeCycles } from 'single-spa';

import { B2BLanguage, SubsiteOptions } from '../../../../common/types/global';
import { efsetLanguageMapper } from '../utils/efsetLanguageMapper';

import { efsetWrapper } from './components/efsetWrapper';

interface UrlParams {
    lang: null|B2BLanguage;
    isMobile: null|string;
    retakePlacementTest: null|string;
}

const blurbs = {
    717298: 'Exit',
};

const blurbIds = Object.keys(blurbs).map(String);
let $efsetWrapper: HTMLElement;
let $script: HTMLScriptElement;
let initEfset: EventListener|EventListenerObject;

const getUrlParams = (): UrlParams => {
    const query = window.location.search;
    const urlParams = new URLSearchParams(query);
    const lang = urlParams.get('lang');
    const isMobile = urlParams.get('isMobile');
    const retakePlacementTest = urlParams.get('retake');

    return {
        lang: lang as B2BLanguage || null,
        isMobile,
        retakePlacementTest,
    };
};

function mount(props: SubsiteOptions): Promise<unknown> {
    const { student, envConfig } = props;
    const { lang, isMobile, retakePlacementTest } = getUrlParams();
    const language = lang || props.lang;

    return props.loadTranslations({ keys: blurbIds, fallback: blurbs })
        .then((translate) => {
            if (student) {
                initEfset = (): void => {
                    // After completing EFSET student going to be redirected to:
                    // - onboarding page with webIntegrationId
                    // - progress and goals page with webRetakeIntegrationId
                    const webIntegrationId = retakePlacementTest
                        ? envConfig.efset30.webRetakeIntegrationId
                        : envConfig.efset30.webIntegrationId;

                    /* eslint-disable @typescript-eslint/camelcase */
                    // eslint-disable-next-line
                    // @ts-ignore
                    window.efset.init(isMobile ? envConfig.efset30.mobileIntegrationId : webIntegrationId, {
                        userId: student.id, // required in most journeys
                        languageCode: efsetLanguageMapper(language),
                        userDetails: {
                            email: student.emailAddress,
                            familyName: student.lastName,
                            givenName: student.firstName,
                            countryCode: student.country,
                        },
                    });
                };

                $script = document.createElement('script');
                $script.setAttribute('defer', '');
                $script.setAttribute('id', 'efset30-script');
                $script.setAttribute('crossorigin', 'anonymous');
                $script.setAttribute('type', 'text/javascript');
                $script.src = envConfig.efset30.script;
                document.head.appendChild($script);

                $efsetWrapper = document.body.appendChild(efsetWrapper(props, [], {
                    includeExit: !isMobile,
                    includeLangSwitcher:
                false, // we don't need it, both on mobile app and website
                    text: {
                        exit: translate(717298),
                    },
                }));

                document.addEventListener('efset_loader_ready', initEfset);

                const $quitTest = document.querySelector('.js-efset-quit');
                if ($quitTest) {
                    $quitTest.addEventListener(
                        'click',
                        () => {
                            const quitTestUrl = retakePlacementTest
                                ? props.config.paths.progressAndGoals.url
                                : props.config.paths.onboarding.url;

                            return window.history.pushState(
                                null,
                                '',
                                quitTestUrl,
                            );
                        },
                    );
                }
            } else {
                props.onMessage({
                    type: 'error',
                    display: {
                        title: 'Error',
                        message: 'Something went wrong. Please try again later.',
                    },
                    log: {
                        context: 'Efset30 mount error',
                        message: `Expected student to be defined, received ${student}`,
                    },
                });
            }
        });
}

function unmount(): Promise<unknown> {
    // eslint-disable-next-line
  // @ts-ignore
    window.efsetWidget = undefined;
    if ($script) {
        document.head.removeChild($script);
    }

    if ($efsetWrapper) {
        document.body.removeChild($efsetWrapper);
    }

    if (initEfset) {
        document.removeEventListener('efset_loader_ready', initEfset);
    }

    return Promise.resolve();
}

export const efset30: LifeCycles<SubsiteOptions> = {
    mount,
    unmount,
    bootstrap: () => Promise.resolve(),
};
