import bg1 from '../../../../../common/style/assets/img/not-found-1.png';
import img404 from '../../../../../common/style/assets/img/not-found-2.png';
import img500 from '../../../../../common/style/assets/img/server-error.png';
import bg3 from '../../../../../common/style/assets/img/not-found-3.png';

export interface ErrorOptions {
    title: string;
    infoText: string;
    buttonText: string;
    buttonType: 'Logout' | 'TakeMeHome';
    image?: '404' | '500';
    fullScreen?: boolean;
    dataQA?: string;
}

const logoutClass = 'js-logout-btn';

const error = (_options: ErrorOptions): string => {
    const options: ErrorOptions = {
        image: '500',
        fullScreen: true,
        dataQA: 'error-page',
        ..._options,
    };

    const mainImage = options.image === '404'
        ? img404
        : img500;

    const button = options.buttonType === 'TakeMeHome'
        ? `<a class="c-button -primary" href="/" data-qa="take-me-home">${options.buttonText}</a>`
        : `<button class="${logoutClass} c-button -primary" data-qa="logout-button">${options.buttonText}</button>`;

    /* eslint-disable max-len */
    return `
        <div class="c-error-page${options.fullScreen ? ' -full-size' : ''}" style="background-image: url(${bg1}), url(${mainImage}), url(${bg3})" data-qa="${options.dataQA}">
            <div class="l-container">
                <h1 class="c-error-page__title">${options.title}</h1>
                <h2 class="c-error-page__info">${options.infoText}</h2>
                ${button}
            </div>
        </div>
    `;
};

export { error, logoutClass };
