import { v4 as uuidv4 } from 'uuid';

import { AuthTokens } from '../../../common/types/global';
import { fetchApiIsSupported } from '../../../common/Elm/src/JsUtils/featureCheck.js';
import { error } from './utils/logging';
import { Student } from '../../../server/src/types/student';


const isInternalRequest = (request: string): boolean => request.startsWith(window.origin) || !request.startsWith('http');

const originalFetch = fetch;

/**
 * if we are running from within cypress we will create a wrapped version of fetch that will
 * automatically add the requested mock headers to every request
 */
const mockingFetch = (input: RequestInfo, init: RequestInit = {}): Promise<Response> => {
    if (!window.Cypress) {
        // If it's an external request we remove the sensitive headers
        if (!isInternalRequest(input as string)) {
            if (init.headers) {
                // eslint-disable-next-line
                // @ts-ignore
                // eslint-disable-next-line
                delete init.headers.authorization;
                // eslint-disable-next-line
                // @ts-ignore
                // eslint-disable-next-line
                delete init.headers['x-ef-access'];
            }
        }

        return originalFetch.call(window, input, init);
    }

    return originalFetch.call(window, input, {
        ...init,
        headers: {
            ...init.headers,
            ...window.Cypress.customHeaders,
        },
    });
};

window.fetch = mockingFetch;

/**
 * This will create an augmented version of the fetch api that automatically adds the correct
 * auth headers to each request so that the rest of the code doesn't really need to worry about it.
 * This wrapper will also take of intercepting and logging failures and performing a retry where
 * appropriate.
 * Because we are wrapping the global instance of fetch, we do not need to explicity pass any
 * http client to the subsites. This is both good and bad. We shall see how it pans out.
 * @param tokens {AuthTokens} the authentication tokens provided to us by the platform auth library
 * TODO we also need to intercept 401 responses and redirect to the login page (is there a helper in the platform auth
 * lib that helps us with the redirection to avoid reproducing that config?)
 */
export function wrapFetch(tokens: AuthTokens, student?: Student, debugBlurbs = false): void {
    // TODO we should not append tokens to http headers for external calls https://efcloud.atlassian.net/browse/EFCS-162

    const lang = 'en';

    window.fetch = (input: RequestInfo, init: RequestInit = {}): Promise<Response> => {
        let headers;
        // In browsers without fetch support (IE11) the fetch polyfill is applied which transforms fetch requests to XmlHttpRequest.
        // Because those XmlHttpRequest already get the headers appended (initialize.ts), we shouldn't do it here as well,
        // otherwise they get merged (values are present twice) which won't work.
        // eslint-disable-next-line
        // @ts-ignore
        if (fetchApiIsSupported) {
            headers = {
                'Content-Type': 'application/json',
                'accept-language': lang,
                Authorization: `Bearer ${tokens.access}`,
                'x-ef-access': tokens.account,
                'x-timezone': student?.clientTimezone.key ?? 'GMT Standard Time',
                'x-debug-blurbs': debugBlurbs.toString(),
                'x-ef-correlation-id': uuidv4(),
                ...init.headers,
            };
        } else {
            headers = {
                'Content-Type': 'application/json',
                'accept-language': lang,
                ...init.headers,
            };
        }

        return mockingFetch.call(window, input, { ...init, headers }).catch((err) => {
            // not entirely sure what to do in this circumstance. Do we log it or do we assume that
            // server will already have logged it. If it came from the bff then it should
            // already have been logged.
            error(
                `We received the following error making an api call to ${input}: ${err}`,
            );
            // rethrow because we don't really have any way to generically handle the failure, and
            // also the client code may well have implemented its own error handling
            throw err;
        });
    };
}


/**
 * We overwrite the open property in order to get access to the requested URL so
 * we can decide whether or not to add sensitive headers
 */
let isInternalXhrRequest = false;

const originalXHROpen = window.XMLHttpRequest.prototype.open;

window.XMLHttpRequest.prototype.open = function newXHROpen(method: string, url: string) { // eslint-disable-line
    isInternalXhrRequest = isInternalRequest(url);

    // eslint-disable-next-line
    // @ts-ignore
    return originalXHROpen.apply(this, [method, url]);
};

/**
 * What follows is a trick to override the xhr send function so that we get an opportunity at the last
 * minute to inject auth headers and any mock headers that cypress wants to add. This is similar to
 * what we are doing when we wrap the fetch function but for xhr in case any subsite wants (or needs) to
 * use xhr instead of fetch. Most notably, the elm runtime uses xhr.
 */
const sendDescriptor = Object.getOwnPropertyDescriptor(XMLHttpRequest.prototype, 'send');

export function wrapXHR(tokens: AuthTokens, student?: Student, debugBlurbs = false): void {
    Object.defineProperty(XMLHttpRequest.prototype, 'send', {
        get() {
            if (tokens && isInternalXhrRequest) {
                this.setRequestHeader('authorization', `Bearer ${tokens.access}`);
                this.setRequestHeader('x-ef-access', tokens.account);
                this.setRequestHeader('x-ef-correlation-id', uuidv4());
            }
            if (student) {
                this.setRequestHeader('x-timezone', student.clientTimezone.key);
            }
            this.setRequestHeader('x-debug-blurbs', debugBlurbs.toString());
            if (window.Cypress && window.Cypress.customHeaders) {
                Object.entries(window.Cypress.customHeaders).forEach(([k, v]) => {
                    this.setRequestHeader(k, v);
                });
            }
            return sendDescriptor && sendDescriptor.value;
        },
        configurable: true,
    });
}
