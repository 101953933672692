/* eslint-disable no-console */
import { navigateToUrl } from 'single-spa';
import { getPaths } from './config';
import { InitializeResponse, ErrorPageTypes } from '../../../common/types/global';
import { initialize, UserStatus } from './services/initialize';
import { initTracking, trackEvent } from './utils/tracking';

export async function bootstrap(): Promise<InitializeResponse> {
    return initialize().then(async (response: InitializeResponse) => {
        const currentPath = window.location.pathname;

        initTracking(response.student);
        // In order to start a session in amplitude we need to trigger an event
        // Since the access token is valid for a longer period than an amplitude sesssion timout (30 minutes)
        // the user might not need to login again after some time of inactivity. In that case we still want
        // to start a new session by triggering the 'school-access' event
        trackEvent(response.withSignIn ? 'signed-in' : 'school-access');

        const paths = getPaths(response.student?.grantsAndCredits);

        // Todo: Should also redirect users trying to access features without having grants for it?
        // And how do we do that if user access page not through hard reload (and therefore this bootstrap)
        switch (response.status) {
            case UserStatus.Assessment:
                if (currentPath !== paths.efset50.url) {
                    navigateToUrl(paths.efset50.url);
                }
                break;
            case UserStatus.Enroled:
                if (currentPath === paths.onboarding.url) {
                    if (paths.home.external) {
                        window.location.href = paths.home.url;
                    } else {
                        navigateToUrl(paths.home.url);
                    }
                }
                break;
            case UserStatus.Unenroled:
                // We don't want to redirect on /efset30 which is taken during onboarding and /efset50
                if (currentPath !== paths.efset30.url && currentPath !== paths.efset50.url) {
                    if (paths.onboarding.external) {
                        setTimeout(() => { window.location.href = `${paths.onboarding.url}${window.location.search}`; });
                    } else {
                        navigateToUrl(`${paths.onboarding.url}${window.location.search}`);
                    }
                }
                break;
            case UserStatus.Unlicensed:
                // todo: Unlicensed users might still access account settings, tbd
                // BS have implemented an auto-renewal flow for unlicensed students if redirec it present on the
                // response navigate there else navigate to the existing error page
                if (response.autoRenewalRedirect) {
                    setTimeout(() => { window.location.href = response?.autoRenewalRedirect || '/'; });
                } else {
                    navigateToUrl(`${paths.errorPage.url}?type=${ErrorPageTypes.Unlicensed}`);
                }
                break;
            default:
                break;
        }

        return response;
    });
}
