import { trackEvent } from './tracking';

let currentPage = '';
let referrer;

window.addEventListener('single-spa:routing-event', () => {
    if (currentPage === '') { // first time through
        referrer = window.document.referrer;
        currentPage = window.location.pathname;
        trackEvent('page_view', {
            name: currentPage,
            referrer,
        });
    } else if (window.location.href !== currentPage) {
        referrer = currentPage;
        currentPage = window.location.pathname;
        trackEvent('page_view', {
            name: currentPage,
            referrer,
        });
    }
});
