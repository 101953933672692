import { LifeCycles } from 'single-spa';
import {
    SubsiteOptions,
    ResourceLookupFunc,
    ErrorPageTypes,
} from '../../../../common/types/global';
import {
    error, logoutClass, ErrorOptions,
} from './components/error';

const blurbs = {
    // Title
    718942: 'Sorry!',
    // Generic error message
    728088: 'An error has occurred. Please try again later.',
    // Unlicensed
    718943: 'You do not have an active license.',
    718944: 'Please contact your HR training manager to access to learn on English Live.',
    // Efset50 No remaining Credits
    724956: 'You do not have remaining credits.',
    724957: 'Please contact your HR training manager for access to the test.',
    // Button Text
    718688: 'Log out',
    724632: 'Take me home',
};

const blurbIds = Object.keys(blurbs).map(String);

const getConfig = (translate: ResourceLookupFunc, errorPageType: ErrorPageTypes): ErrorOptions => {
    switch (errorPageType) {
        case ErrorPageTypes.Fatal:
            return {
                title: translate(718942),
                infoText: translate(728088),
                buttonText: translate(718688),
                buttonType: 'Logout',
            };

        case ErrorPageTypes.InApp:
            return {
                title: translate(718942),
                infoText: translate(728088),
                buttonText: translate(724632),
                buttonType: 'TakeMeHome',
            };

        case ErrorPageTypes.Unlicensed:
            return {
                title: translate(718942),
                infoText: `${translate(718943)} ${translate(718944)}`,
                buttonText: translate(718688),
                buttonType: 'Logout',
                dataQA: 'unlicensed',
            };

        case ErrorPageTypes.NoEfset50Credits:
            return {
                title: translate(718942),
                infoText: `${translate(724956)} ${translate(724957)}`,
                buttonText: translate(718688),
                buttonType: 'Logout',
            };

        default:
            return {
                title: translate(718942),
                infoText: translate(728088),
                buttonText: translate(718688),
                buttonType: 'Logout',
            };
    }
};

const $app = document.getElementById('app-error');

function mount(props: SubsiteOptions): Promise<unknown> {
    return props.loadTranslations({ keys: blurbIds, fallback: blurbs }).then((translate) => {
        const query = window.location.search;
        const urlParams = new URLSearchParams(query);
        const errorPageType = urlParams.get('type') as ErrorPageTypes || 'fatal';
        const config = getConfig(translate, errorPageType);

        if ($app) {
            $app.innerHTML = error(config);

            const logout = document.querySelector(`.${logoutClass}`);
            if (logout && config.buttonType === 'Logout') {
                logout.addEventListener('click', () => {
                    document.dispatchEvent(new CustomEvent('platform-auth.logout'));
                });
            }
        }
    });
}

function unmount(): Promise<void> {
    if ($app) {
        $app.innerHTML = '';
    }

    return Promise.resolve();
}

export const errorPage: LifeCycles<SubsiteOptions> = {
    mount,
    unmount,
    bootstrap: () => Promise.resolve(),
};
