type StudentInfo = {
    firstName: string;
    lastName: string;
    emailAddress: string;
    userId: string;
}

export function loadLivechat(config: LivechatConfig, lang: string, studentInfo: StudentInfo, helpButtonVisible: boolean): void {
    const initESW = (gslbBaseURL: string | null): void => {
        if (!window.embedded_svc) {
            return;
        }

        window.embedded_svc.settings.displayHelpButton = helpButtonVisible;
        window.embedded_svc.settings.language = lang;

        window.embedded_svc.settings.prepopulatedPrechatFields = {
            FirstName: studentInfo.firstName,
            LastName: studentInfo.lastName,
            Email: studentInfo.emailAddress,
        };

        window.embedded_svc.settings.enabledFeatures = ['LiveAgent'];
        window.embedded_svc.settings.entryFeature = 'LiveAgent';

        window.embedded_svc.settings.extraPrechatFormDetails = [{
            label: 'azureId',
            value: studentInfo.userId,
            displayToAgent: true,
        }];

        window.embedded_svc.settings.extraPrechatInfo = [{
            entityFieldMaps: [{
                fieldName: 'AzureObjectId__c',
                label: 'azureId',
                doCreate: true,
                doFind: true,
                isExactMatch: true,
            }],
            entityName: 'Contact',
            saveToTranscript: 'ContactId',
            showOnCreate: true,
            linkToEntityName: 'Case',
            linkToEntityField: 'ContactId',
        }];

        window.embedded_svc.init(
            config.baseCoreURL,
            config.communityEndpointURL,
            gslbBaseURL,
            config.organizationId,
            config.eswConfigDevName,
            {
                baseLiveAgentContentURL: config.baseLiveAgentContentURL,
                deploymentId: config.deploymentId,
                buttonId: config.buttonId,
                baseLiveAgentURL: config.baseLiveAgentURL,
                eswLiveAgentDevName: config.eswLiveAgentDevName,
                isOfflineSupportEnabled: false,
            },
        );
    };

    if (!window.embedded_svc) {
        const s = document.createElement('script');
        s.setAttribute('src', config.scriptURL);
        s.onload = (): void => {
            initESW(null);
        };
        document.body.appendChild(s);
    } else {
        initESW('https://service.force.com');
    }
}

export function showChatButton(): void {
    if (window.embedded_svc) {
        window.embedded_svc.showHelpButton();
    }
}

export function hideChatButton(): void {
    if (window.embedded_svc) {
        window.embedded_svc.hideHelpButton();
    }
}

export type LivechatConfig = {
    baseCoreURL: string;
    communityEndpointURL: string;
    organizationId: string;
    eswConfigDevName: string;
    baseLiveAgentContentURL: string;
    deploymentId: string;
    buttonId: string;
    baseLiveAgentURL: string;
    eswLiveAgentDevName: string;
    scriptURL: string;
};

declare global {
    interface Window {
        embedded_svc?: {
            hideHelpButton: () => void;
            showHelpButton: () => void;
            settings: {
                displayHelpButton: boolean;
                language: string;
                enabledFeatures: string[];
                entryFeature: string;
                extraPrechatFormDetails: {
                    label: string;
                    value: string;
                    displayToAgent: boolean;
                }[];
                extraPrechatInfo: {
                    entityFieldMaps: {
                        doCreate: boolean;
                        doFind: boolean;
                        fieldName: string;
                        isExactMatch: boolean;
                        label: string;
                    }[];
                    entityName: string;
                    saveToTranscript: string;
                    showOnCreate: boolean;
                    linkToEntityName: string;
                    linkToEntityField: string;
                }[];
                prepopulatedPrechatFields: {
                    FirstName?: string;
                    LastName?: string;
                    Email?: string;
                    Subject?: string;
                };
            };
            init: (
                baseCoreURL: string,
                communityEndpointURL: string,
                gslbBaseURL: string | null,
                organizationId: string,
                eswConfigDevName: string,
                settings: {
                    baseLiveAgentContentURL: string;
                    deploymentId: string;
                    buttonId: string;
                    baseLiveAgentURL: string;
                    eswLiveAgentDevName: string;
                    isOfflineSupportEnabled: boolean;
                },
            ) => void;
        };
    }
}
