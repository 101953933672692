import Cookies from 'js-cookie';
import { v4 as uuidv4 } from 'uuid';
import { navigateToUrl } from 'single-spa';

import { InitializeResponse, RefreshAccountTokenResponse, AuthTokens } from '../../../../common/types/global';
import { config } from '../config';
import { wrapFetch, wrapXHR } from '../http';
import { onStudentChange } from '../messaging';
import { AuthenticationError } from '../utils/errors';
import { trackEvent } from '../utils/tracking';

// I am having to redefine this enum here in the shell even though it ought to be coming from the
// bff definition. There was some sort of babel problem compiling across projects. Not sure what it is
// but we will need to solve it. Might just be something weird about enums. Not sure.
export enum UserStatus {
    Enroled = 'Enroled',
    Unenroled = 'Unenroled',
    Unlicensed = 'Unlicensed',
    Assessment = 'Assessment'
}

const STATE_KEY = 'state';
const ACCESS_TOKEN_KEY = 'access';
const SCHOOL_ID_KEY = 'schoolId';

let currentTokens: AuthTokens | undefined;

function logout(): void {
    // Not guaranteed that this event goes through but I guess that's fine.
    // We can make it synchrounous in case we need reliability
    trackEvent('logged-out');
    window.location.href = `${config.paths.server.url}/auth/logout`;
}

/**
 * And here we have another special trick to intercept the load event of any XHR request and if
 * we receive a 401, we will need to automatically redirect to the login page
 */
function intercept401(): void {
    const ael = XMLHttpRequest.prototype.addEventListener;
    XMLHttpRequest.prototype.addEventListener = function addEventListener(
        ev: string,
        listener: EventListener,
    ): void {
        if (ev === 'load') {
            ael.call(this, ev, (...args) => {
                if (this.status === 401) {
                    // navigate to the bff's logout endpoint, this will redirect to the login controller
                    // without us having to know anything about how that happens on the front end
                    logout();
                }
                return listener.apply(this, args);
            });
            return;
        }
        ael.call(this, ev, listener);
    };
}

// we expect a hash fragment with various name value pairs in the same for as a query string
export function extractValueFromHash(
    hash: string = window.location.hash,
    param: string,
): string | undefined {
    const params = new URLSearchParams(hash.slice(1));
    const val = params.get(param);
    if (!val) {
        return undefined;
    }
    return decodeURIComponent(val.replace(/\+/g, ' '));
}

export function getStateFromHash(
    hash: string = window.location.hash,
): string | undefined {
    const state = extractValueFromHash(hash, STATE_KEY);
    if (state) {
        return state.slice(1).replace(/"/g, '');
    }
    return undefined;
}

export function getAccessTokenFromHash(
    hash: string = window.location.hash,
): string | undefined {
    return extractValueFromHash(hash, ACCESS_TOKEN_KEY);
}

export function getAccessTokenFromCookies(): string | undefined {
    const tokens = Cookies.get('efid_tokens');
    if (tokens) {
        const parsed = JSON.parse(decodeURIComponent(tokens));
        return parsed.access;
    }
    return undefined;
}

export function getSchoolIdFromHash(
    hash: string = window.location.hash,
): string | undefined {
    return extractValueFromHash(hash, SCHOOL_ID_KEY);
}

// will remove the entire hash, including #
function removeHash(): void {
    window.history.pushState(null, '', window.location.pathname + window.location.search);
}

function interceptAuthRedirect<T>(res: Response): Promise<T> {
    if (!res.ok) {
        if (res.status === 403) {
            return res.json().then(({ redirectTo }) => {
                window.location.assign(redirectTo);
                throw new AuthenticationError('Redirecting to login controller');
            });
        }
        throw new Error(`Call to ${res.url} failed: ${res.status} (${res.statusText})`);
    } else {
        return res.json();
    }
}

function refreshAccountToken(
    accessToken: string,
    schoolId: string,
    domainHint: string,
    partnerCode: string,
    ssoTokenHint: string,
): Promise<RefreshAccountTokenResponse> {
    const qs = new URLSearchParams();
    qs.append('schoolid', schoolId);
    qs.append('state', window.location.pathname);
    qs.append('domainHint', domainHint);
    qs.append('partnerCode', partnerCode);
    qs.append('ssoTokenHint', ssoTokenHint);
    const url = `${config.paths.server.url}/auth/refreshaccounttoken?${qs.toString()}`;
    return fetch(url, {
        method: 'get',
        headers: { 'content-type': 'application/json' },
    })
        .then<RefreshAccountTokenResponse>(interceptAuthRedirect)
        .then((res: RefreshAccountTokenResponse) => {
            currentTokens = {
                access: accessToken,
                account: res.accountToken,
            };
            Cookies.set('efid_tokens', JSON.stringify(currentTokens));
            wrapXHR(currentTokens);
            wrapFetch(currentTokens);
            if (res.timeToRefresh > 0) {
                setTimeout(() => {
                    refreshAccountToken(accessToken, schoolId, domainHint, partnerCode, ssoTokenHint);
                }, res.timeToRefresh);
            }
            return res;
        });
}

function guessTimezone(): string | undefined {
    try {
        return new Intl.DateTimeFormat().resolvedOptions().timeZone;
    } catch (_err) {
        // This might not work on IE11
        return undefined;
    }
}

export function initialize(): Promise<InitializeResponse> {
    const qs = new URLSearchParams(`state=${window.location.pathname}`);
    const hashAccessToken = getAccessTokenFromHash();
    const accessToken = hashAccessToken || getAccessTokenFromCookies();
    const state = getStateFromHash();
    const schoolId = getSchoolIdFromHash();
    const clientTimezone = guessTimezone();
    const debugBlurbs = localStorage.getItem('debugBlurbs') === 'true';

    // We need to send the b2blang cookie so that the BFF can set the correct lng cookie
    // for the legacy language, otherwise the App Shell and legacy apps get out of sync
    Cookies.set('b2blang', JSON.parse(localStorage.getItem('lang') || '"en"'));

    if (schoolId) {
        qs.append('schoolid', schoolId);
    }
    if (clientTimezone) {
        qs.append('clientTimezone', clientTimezone);
    }
    const url = `${config.paths.server.url}/auth/initialize?${qs.toString()}`;

    // this is the only call we make to the BFF before we wrap the fetch
    // this means that it will not have any authz headers *automatically*
    // We need to add the authz header manually based on either the token from
    // cookies or from the hash

    const headers: Record<string, string> = accessToken
        ? {
            'content-type': 'application/json',
            'x-ef-correlation-id': uuidv4(),
            authorization: `Bearer ${accessToken}`,
        }
        : {
            'content-type': 'application/json',
            'x-ef-correlation-id': uuidv4(),
        };

    return fetch(url, {
        method: 'get',
        headers,
    })
        .then<InitializeResponse>(interceptAuthRedirect)
        .then((res: InitializeResponse) => {
            res.withSignIn = false;
            currentTokens = {
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                access: accessToken!,
                account: res.accountToken,
            };
            Cookies.set('efid_tokens', JSON.stringify(currentTokens));
            wrapXHR(currentTokens, res.student, debugBlurbs);
            wrapFetch(currentTokens, res.student, debugBlurbs);
            intercept401();
            if (res.timeToRefresh > 0) {
                setTimeout(() => {
                    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                    refreshAccountToken(accessToken!, res.schoolId, res.domainHint, res.partnerCode, res.ssoTokenHint);
                }, res.timeToRefresh);
            }
            if (hashAccessToken) {
                res.withSignIn = true;
                removeHash();
                if (state) {
                    navigateToUrl(state);
                }
            }
            return res;
        });
}

// this is here for backward compatibility, just in case anything still thinks that it's using platform auth
document.addEventListener('platform-auth.logout', logout);

onStudentChange((student) => {
    if (currentTokens) {
        wrapXHR(currentTokens, student);
        wrapFetch(currentTokens, student);
    }
});
