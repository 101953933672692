import efLogoSimple from '../../../../../common/style/assets/img/ef-logo--simple.svg';
import triangleDown from '../../../../../common/style/assets/svg/icons/triangle-down.svg';
import {
    SubsiteOptions,
    LanguageObject,
    B2BLanguage,
} from '../../../../../common/types/global';

interface WrapperOptions {
    includeExit: boolean;
    includeLangSwitcher: boolean;
    text: {
        exit?: string;
    };
}

const styles = `
    #fileUploadForm {
        display: none;
    }

    .efset-quit {
        color: #009EE8;
        position: absolute;
        left: 24px;
        background-color: transparent;
        border: 0;
        outline: 0;
        cursor: pointer;
        z-index: 11;
    }

    .efset-quit:focus,
    .efset-quit:hover {
        color: #15AAFB;
    }

    .efset-header {
        position: relative;
        background-color: #fff;
        display: flex;
        align-items: center;
        height: 60px;
        border-bottom: 1px solid rgba(25,25,25,0.1);
        z-index: 10;
        justify-content: center;
    }

    .efset-header::after {
        content: '';
        position: absolute;
        top: 100%;
        width: 100%;
        height: 28px;
        display: block;
        background-image: linear-gradient(180deg,rgba(0,0,0,.15),transparent);
        pointer-events: none;
    }

    .efset-header__logo {
        width: 55px;
        height: 32px;
        background-image: url(${efLogoSimple});
        background-size: contain;
        background-repeat: no-repeat;
        background-position: 50%; 
    }

    .efset-header__logo_custom {
        max-width: 130px;
        max-height: 34px;
        object-fit: contain;
    }

    .efset-header__lang-switcher-w {
        position: absolute;
        right: 30px;
    }

    .efset-header__lang-switcher-w::after {
        position: absolute;
        right: 16px;
        margin-left: 0;
        top: 50%;
        transform: translateY(-50%);
        content: '';
        display: block;
        width: 16px;
        height: 16px;
        pointer-events: none;
        background-image: url(${triangleDown});
        background-size: 12px;
        background-repeat: no-repeat;
        background-position: 50%;
    }

    .efset-header__lang-switcher {
        padding: 8px 16px;
        font-size: 14px;
        background-color: transparent;
        -webkit-appearance: none;
        padding-right: 30px;
        cursor: pointer;
        border: none;
        box-shadow: none;
    }

    @media screen and (max-width: 767px) {
        .efset-quit {
            left: 130px;
        }

        .efset-header {
            justify-content: flex-start;
        }

        .efset-header__logo {
            margin-left: 30px;
        }
    }
`;

const efsetWrapperEl = document.createElement('div');
efsetWrapperEl.setAttribute('id', 'efset');

const headerMarkup = (
    languages: LanguageObject[],
    currentLanguage: B2BLanguage,
    options: WrapperOptions,
    externalLogo?: string,
): string => {
    const logoElement = externalLogo
        ? `<img class="efset-header__logo_custom" src="${externalLogo}" />`
        : '<span class="efset-header__logo"></span>';

    return `
        <header class="efset-header u-negativ-header-offset" data-qa="efset-header">
            ${options.includeExit ? `<div class="efset-quit js-efset-quit">${options.text.exit || 'Exit'}</div>` : ''}
            <style>${styles}</style>
            ${logoElement}
            ${options.includeLangSwitcher ? `
                <div class="efset-header__lang-switcher-w">
                    <select class="efset-header__lang-switcher js-efset-lang-switcher">
                        ${languages.map((lang) => `
                            <option value="${lang.value}"${currentLanguage === lang.value ? ' selected' : ''}>${lang.label}</option>
                        `).join('')}
                    </select>
                </div>
            ` : ''}
        </header>
    `;
};

const activateLanguageSwitcher = (props: SubsiteOptions): void => {
    const $langSwitcher = efsetWrapperEl.querySelector('.js-efset-lang-switcher');

    if ($langSwitcher) {
        $langSwitcher.addEventListener('change', (e: Event) => {
            if (e) {
                props.changeLanguage((e.target as HTMLSelectElement).value as B2BLanguage);
            }
        });
    }

    props.onLanguageChange({
        callback: () => {
            window.location.reload();
        },
        translationsRequest: () => ({ keys: [] }),
    });
};

const efsetWrapper = (props: SubsiteOptions, efsetLanguages: LanguageObject[], options = {}): HTMLElement => {
    const wrapperOptions = {
        includeExit: false,
        includeLangSwitcher: false,
        text: {},
        ...options,
    };

    efsetWrapperEl.innerHTML = headerMarkup(efsetLanguages, props.lang, wrapperOptions, props.externalLogo);

    if (wrapperOptions.includeLangSwitcher) {
        activateLanguageSwitcher(props);
    }

    return efsetWrapperEl;
};

export { efsetWrapper };
