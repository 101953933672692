export function getItem<T>(key: string): Promise<T|null|undefined> {
    const asString = localStorage.getItem(key);
    return Promise.resolve(asString
        ? JSON.parse(asString)
        : undefined);
}

export function setItem<T>(key: string, val: T): Promise<T> {
    localStorage.setItem(key, JSON.stringify(val));
    return Promise.resolve(val);
}
