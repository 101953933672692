import { LifeCycles, navigateToUrl } from 'single-spa';
import { SubsiteOptions, LanguageObject, ErrorPageTypes } from '../../../../common/types/global';
import { efsetLanguageMapper, filterEfsetLanguages } from '../utils/efsetLanguageMapper';
import { efsetWrapper } from './components/efsetWrapper';
import { config } from '../config';

let $efsetWrapper: HTMLElement;
let $script: HTMLScriptElement;

let initEfset: EventListener | EventListenerObject;

function mount(props: SubsiteOptions): Promise<unknown> {
    const { student, envConfig } = props;


    if (student) {
        const efset50Credits = student.grantsAndCredits.efset50;
        // EFSET50 should always be a credit, not a agrant
        const hasEfset50Credits = efset50Credits && (efset50Credits.quota !== 'unlimited') && efset50Credits.quota.remaining > 0;

        if (!hasEfset50Credits) {
            navigateToUrl(`${config.paths.errorPage.url}?type=${ErrorPageTypes.NoEfset50Credits}`);
            return Promise.resolve();
        }

        initEfset = (): void => {
            /* eslint-disable @typescript-eslint/camelcase */
            // eslint-disable-next-line
            // @ts-ignore
            window.efset.init(envConfig.efset50.integrationId, {
                userId: student.id, // required in most journeys
                languageCode: efsetLanguageMapper(props.lang),
                userDetails: {
                    email: student.emailAddress,
                    familyName: student.lastName,
                    givenName: student.firstName,
                    countryCode: student.country,
                },
            });
        };

        $script = document.createElement('script');
        $script.setAttribute('defer', '');
        $script.setAttribute('id', 'efset50-script');
        $script.setAttribute('crossorigin', 'anonymous');
        $script.setAttribute('type', 'text/javascript');
        $script.src = envConfig.efset50.script;
        document.head.appendChild($script);

        $efsetWrapper = document.body.appendChild(efsetWrapper(
            props,
            filterEfsetLanguages(props.config.languages as LanguageObject[]), {
                includeLangSwitcher: true,
            },
        ));

        document.addEventListener('efset_loader_ready', initEfset);
    } else {
        props.onMessage({
            type: 'error',
            display: {
                title: 'Error',
                message: 'Something went wrong. Please try again later.',
            },
            log: {
                context: 'Efset50 mount error',
                message: `Expected student to be defined, received ${student}`,
            },
        });
    }

    return Promise.resolve();
}

function unmount(): Promise<unknown> {
    // eslint-disable-next-line
    // @ts-ignore
    window.efsetWidget = undefined;
    if ($script) {
        document.head.removeChild($script);
    }

    if ($efsetWrapper) {
        document.body.removeChild($efsetWrapper);
    }

    if (initEfset) {
        document.removeEventListener('efset_loader_ready', initEfset);
    }

    return Promise.resolve();
}

export const efset50: LifeCycles<SubsiteOptions> = {
    mount,
    unmount,
    bootstrap: () => Promise.resolve(),
};
